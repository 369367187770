import { initializeApp } from 'firebase/app';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import settings from '../config/settings';
import { connectFunctionsEmulator, getFunctions } from '@firebase/functions';
import { connectAuthEmulator, getAuth } from '@firebase/auth';
import { getDatabase } from 'firebase/database';
import { getRemoteConfig } from 'firebase/remote-config';

import { getStorage } from 'firebase/storage';

const firebaseApp = initializeApp(settings.firebaseConfig);

export const functions = getFunctions(firebaseApp);
export const auth = getAuth(firebaseApp);
export const firestore = getFirestore(firebaseApp);
export const database = getDatabase(firebaseApp);

export const storage = getStorage();

export const remoteConfig = getRemoteConfig(firebaseApp);

export const Timestamp = firestore.Timestamp;

// connectFirestoreEmulator(firestore, 'localhost', 8080);
// connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
// connectFunctionsEmulator(functions, 'localhost', 5001);
